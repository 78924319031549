import React from "react";
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import Carousel from "../Carousel";

const Home = () => {
  return (
    <>
      <Navbar
        link="/bariatric"
        title={process.env.REACT_APP_COMPANY_NAME + " - Termos e Condições de reembolso"}
        name="Bariatric"
        sell={false}
      />
      <Carousel />
      <Breadcrumb
        link="/bariatric"
        sell={false}
        title={process.env.REACT_APP_COMPANY_NAME + " - Termos e Condições de reembolso"}
        name="Bariatric"
      />
    </>
  );
};

export default Home;
