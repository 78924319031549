// import Navbar from "../components/Navbar";
// import Breadcrumb from "../components/Breadcrumb";
// import { useState, useEffect } from "react";
// import Link from "../components/Link";
// import Schema from "../components/Schema";
// import axios from "axios";
// import Footer from "../components/Footer";

// const Faq = () => {
//   const [Faqs, setFaqs] = useState([]);

//   useEffect(() => {
//     fetchFaqs();
//   }, []);
//   const fetchFaqs = () => {
//     axios
//       .get(process.env.REACT_APP_BACKEND + "faq")
//       .then((res) => {
//         // console.log(res);
//         setFaqs(res.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   return (
//     <>


//       <Navbar link="/faq" title={process.env.REACT_APP_COMPANY_NAME + " - FAQ"} name="Faq" />
//       <Breadcrumb link="/faq" title={process.env.REACT_APP_COMPANY_NAME + " - FAQ"} name="Faq" />
//       <div className="container">
//         <h1>Faqs - {process.env.REACT_APP_COMPANY_NAME}</h1>
//         <hr />
//         <div className="item-container">
//           {Faqs.map((Faq, index) => (
//             <div className="faq" key={Faq.faq.faq._id.$oid}>
//               <h1>Country: {Faq.country.name}</h1>
//               <h1>Question: {Faq.faq.faq.question}</h1>
//               <h3>Answer: {Faq.faq.faq.answer}</h3>
//               <h3>
//                 Answered by:{" "}
//                 <Link
//                   to={
//                     `/author/${Faq.question}` +
//                     " " +
//                     `${Faq.question}`
//                   }
//                 >
//                   @{Faq.question} {Faq.question}
//                 </Link>
//                 {/* <small> in {new Date(Faq.faq.date_created).toDateString()}</small> */}
//               </h3>
//               Country:{" "}
//               <Link to={`/faq/${Faq.country.name}`}>{Faq.country.name}</Link>
//               <hr />
//               <Schema
//                 faq={Faq.faq}
//                 author={Faq.author}
//                 country={Faq.country}
//                 position={index}
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };
// export default Faq;
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from '../containers/Language';
import Link from "../components/Link";
// import Schema from "../components/Schema";
import axios from "axios";
import Footer from "../components/Footer";

const Faq = () => {
  const [Faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetchFaqs();
  }, []);
  const fetchFaqs = () => {
    axios
      .get(process.env.REACT_APP_BACKEND + "/faq")
      .then((res) => {
        console.log(res.data);
        setFaqs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { dictionary } = useContext(LanguageContext);
  return (
    <>


      <Navbar link="/faq" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Faq.FAQ} name="Faq" />
      <Breadcrumb link="/faq" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Faq.FAQ} />
      <div className="container">
        <h1>Faqs - {process.env.REACT_APP_COMPANY_NAME}</h1>
        <hr />
        <div className="item-container">

          {Faqs.map && Faqs.map((Faq, index) => (
            <div className="faq" key={Faq.faq._id.$oid}>
              {/* <h1>Country: {Faq.country.name}</h1> */}
              <h2>Question: <a href={"faq/" + Faq.faq.question}>{Faq.faq.question}</a></h2>
              <h3>Answer: {Faq.faq.answer}</h3>
              <h3>
                Answered by:{" "}
                <Link
                  to={
                    `/author/${Faq.faq.question}` +
                    " " +
                    `${Faq.faq.question}`
                  }
                >
                  @{Faq.faq._id.$oid} {Faq.faq._id.$oid}
                </Link>
                {/* <small> in {new Date(Faq.faq.date_created).toDateString()}</small> */}
              </h3>
              Country:{" "}
              <Link to={`/faq/${Faq.faq.question}`}>{Faq.faq._id.$oid}</Link>
              <hr />
              {/* <Schema
                faq={Faq}
                author={Faq.author}
                country={Faq.country}
                position={index}
              /> */}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Faq;
