import React, { useState, useEffect } from "react";
import CountryNavList from "./components/CountryNavList";
import axios from "axios";

function ProductNav() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetchCategories();
  }, []);
  const fetchCategories = () => {
    axios
      .get(process.env.REACT_APP_BACKEND + "/categories")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      }
      );
  }
  return <CountryNavList categories={categories} />;
}


export default ProductNav;
