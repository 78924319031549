export function formatCurrency(number: number, currency: string) {
  if (currency !== undefined) {
    const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
      currency: currency,
      style: "currency",
    })
    if (currency === 'BRL') {
      if (number < 1000) {
        return CURRENCY_FORMATTER.format(number).replace(".", ",")
      }
      else {
        return CURRENCY_FORMATTER.format(number).replace(".", ",").replace(",", ".")
      }
    }
    else {
      return CURRENCY_FORMATTER.format(number)
    }
  }
  else {
    const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
      currency: "BRL",
      style: "currency",
    })
    if (number < 1000) {
      return CURRENCY_FORMATTER.format(number).replace(".", ",")
    }
    else {
      return CURRENCY_FORMATTER.format(number).replace(".", ",").replace(",", ".")
    }
  }
}
