import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { ProductType } from '../models/ProductType';
import '../style/Create.css';

function Create() {
    const initialProductState: ProductType = {
        _id: "",
        body: { en: "", fr: "", pt: "" },
        brand_id: "",
        category_id: "",
        category_name: { en: "", fr: "", pt: "" },
        date: { en: "", fr: "", pt: "" },
        ean: "",
        image: { en: "", fr: "", pt: "" },
        keywords: "",
        name: { en: "", fr: "", pt: "" },
        price: { cost: 0, margin: 0 },
        quantity: 0,
        rating: 0,
        subcategory_id: "",
        subcategory_name: "",
        subtitle: { en: "", fr: "", pt: "" },
        tax: { en: "", fr: "", pt: "" }
    };

    const [products, setProducts] = useState<ProductType[]>([]);
    const [product, setProduct] = useState<ProductType>(initialProductState);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get<ProductType[]>('http://192.168.1.253:5000/products');
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name.includes('.')) {
            const [field, subfield] = name.split('.');

            setProduct(prevState => {
                const fieldValue = prevState[field as keyof ProductType];

                // Ensure the fieldValue is an object before spreading it
                if (typeof fieldValue === 'object' && fieldValue !== null) {
                    return {
                        ...prevState,
                        [field]: {
                            ...fieldValue,
                            [subfield]: value
                        }
                    };
                }

                return prevState;
            });
        } else {
            setProduct(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await axios.post('http://192.168.1.253:5000/products/create', product);
            fetchProducts();
            setProduct(initialProductState);
        } catch (error) {
            console.error('Error creating product:', error);
        }
    };

    return (
        <div className="container">
            <h1 className="title">Product Management</h1>

            <form onSubmit={handleSubmit} className="form">
                <div className="grid-container">
                    {Object.keys(product).map((key) => {
                        const value = (product as any)[key];
                        if (typeof value === 'object' && value !== null) {
                            return Object.keys(value).map(subKey => (
                                <div className="grid-item" key={`${key}.${subKey}`}>
                                    <label className="label" htmlFor={`${key}.${subKey}`}>
                                        {`${key}.${subKey}`.replace('_', ' ').toUpperCase()}
                                    </label>
                                    <input
                                        type="text"
                                        id={`${key}.${subKey}`}
                                        name={`${key}.${subKey}`}
                                        value={value[subKey] || ''}
                                        onChange={handleChange}
                                        className="input"
                                    />
                                </div>
                            ));
                        } else {
                            return (
                                <div className="grid-item" key={key}>
                                    <label className="label" htmlFor={key}>
                                        {key.replace('_', ' ').toUpperCase()}
                                    </label>
                                    <input
                                        type="text"
                                        id={key}
                                        name={key}
                                        value={value}
                                        onChange={handleChange}
                                        className="input"
                                        required
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
                <button type="submit" className="button">
                    Add Product
                </button>
            </form>

            <div className="grid-container products">
                {products.map(product => (
                    <div className="grid-item product-card" key={product._id}>
                        <h2 className="product-title">{product.name.en}</h2>
                        <h3 className="product-subtitle">{product.subtitle.en}</h3>
                        <p className="product-body">{product.body.en}</p>
                        <p className="product-price">${product.price.cost * product.price.margin}</p>
                        <p className="product-quantity">Stock: {product.quantity}</p>
                        <p className="product-category">Category: {product.category_name.en}</p>
                        <p className="product-subcategory">Subcategory: {product.subcategory_name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Create;
