import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { currencyOptions } from '../currency';
import { CurrencyContext } from '../containers/Currency';
import "../style/Navbar.css";

export default function CurrencySelector() {
    const location = useLocation()
    const { userCurrency, userCurrencyChange } = useContext(CurrencyContext);
    const handleCurrencyChange = e => {
        if (location.pathname === '/checkout') {
            userCurrencyChange(e.target.value)
            window.location.assign('/checkout');
        }
        else {
            userCurrencyChange(e.target.value)
        }
    }
    return (
        <div className='currency'>
            <select
                onChange={handleCurrencyChange}
                value={userCurrency}
            >
                {Object.entries(currencyOptions).map(([id, name]) => (
                    <option key={id} value={id}>{name}</option>
                ))}
            </select>
        </div>
    )
}

