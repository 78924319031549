import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";

import { LanguageContext } from '../containers/Language';
import { CurrencyContext } from "../containers/Currency";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { formatCurrency } from "../components/utils/formatCurrency";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import "../style/Product.css";

const Product = () => {
  const { id } = useParams();
  const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
  const { dictionary } = useContext(LanguageContext);
  const {
    getItemQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    removeFromCart,
    openCart,
    cartQuantity,
  } = useShoppingCart();

  const [data, setData] = useState([]);
  const defaultLanguage = window.localStorage.getItem('netsystems-languages') || process.env.REACT_APP_LANGUAGE || "en";

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_BACKEND}${id ? `/product/${id}` : "/products"}`;
        const response = await axios.get(endpoint);
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProduct();
  }, [id]);

  const calculateProductPrice = (product) => {
    return formatCurrency(
      product.price.cost * (1 + product.price.margin / 100) * currencyFactor,
      currencySymbol
    );
  };

  const renderProduct = (product) => {
    const quantity = getItemQuantity(product._id);
    const productPrice = calculateProductPrice(product);

    return (
      <div className="container" key={product._id}>
        <img
          src={product.image.main}
          className="pietro text-center"
          alt={product.image.alt_main}
        />
        <div className="card-body">
          <h5 className="card-title text-center cardName">
            {product.name[defaultLanguage]}
          </h5>
          <h6 className="card-subtitle text-center">
            {product.subtitle[defaultLanguage]}
          </h6>
          <p className="card-text text-center">
            {product.body[defaultLanguage]}...
          </p>
          <div className="row">
            <div className="col text-center">
              {productPrice}
            </div>
          </div>
          {product.quantity > 0 ? (
            <div className="row">
              <div className="col text-center">
                {quantity === 0 ? (
                  <Button
                    className="w-100 btncolor btnsize"
                    onClick={() => increaseCartQuantity(product._id)}
                  >
                    {dictionary.Cart.AddToCart}
                  </Button>
                ) : (
                  <div className="d-flex align-items-center flex-column gap-2">
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <Button
                        className="btncolor"
                        onClick={() => decreaseCartQuantity(product._id)}
                      >
                        -
                      </Button>
                      <div>
                        <span className="fs-3">{quantity}</span> {dictionary.Cart.AddToCart}
                      </div>
                      <Button
                        className="btncolor"
                        onClick={() => increaseCartQuantity(product._id)}
                      >
                        +
                      </Button>
                    </div>
                    <Button
                      onClick={() => removeFromCart(product._id)}
                      variant="secondary"
                      size="sm"
                    >
                      {dictionary.Cart.DelFrCart}
                    </Button>
                    <Button
                      onClick={openCart}
                      style={{ width: "3rem", height: "3rem", position: "relative" }}
                      className="rounded-circle btncolor"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        fill="currentColor"
                      >
                        <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
                      </svg>
                      <div
                        className="rounded-circle bg-success d-flex justify-content-center align-items-center"
                        style={{
                          color: "white",
                          width: "1.5rem",
                          height: "1.5rem",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          transform: "translate(25%, 25%)",
                        }}
                      >
                        {cartQuantity}
                      </div>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              <Button className="w-100 btn-danger sold-out btnsize">
                {dictionary.Cart.SoldOut}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar
        title={`${process.env.REACT_APP_COMPANY_NAME} - ${dictionary.Product.Product}`}
        name={dictionary.Product.Product}
      />
      <Breadcrumb
        title={`${process.env.REACT_APP_COMPANY_NAME} - ${dictionary.Product.Product}`}
        name={dictionary.Product.Product}
      />
      <div className="faq container text-center">
        <h1>{id ? dictionary.Product.Product : dictionary.Product.AllProducts}</h1>
      </div>
      {data.map(renderProduct)}
      <Footer name={dictionary.Product.Product} />
    </>
  );
};

export default Product;
