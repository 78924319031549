import { useState } from 'react';
import { useNavigate } from "react-router-dom";

function useToken() {
    let navigate = useNavigate();
    function getToken() {
        const userToken = localStorage.getItem('token');
        return userToken && userToken
    }

    const [token, setToken] = useState(getToken());

    function saveToken(userToken) {
        localStorage.setItem('token', userToken);
        setToken(userToken);
        navigate(0, { replace: false });
    };

    function removeToken() {
        localStorage.removeItem("token");
        setToken(null);
        navigate(0, { replace: false });
    }

    return {
        setToken: saveToken,
        token,
        removeToken
    }

}

export default useToken;