import React, { createContext, ReactNode, useContext, useState, useMemo, useEffect } from "react";
import { ShoppingCart } from "../components/ShoppingCart";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useProducts } from "../Products";

type ShoppingCartProviderProps = {
  children: ReactNode;
};

type CartItemType = {
  id: string;
  quantity: number;
  discount: number;
};

type ShoppingCartContextType = {
  openCart: () => void;
  closeCart: () => void;
  clearCart: () => void;
  getItemQuantity: (id: string) => number;
  increaseCartQuantity: (id: string, discount?: number) => void;
  decreaseCartQuantity: (id: string) => void;
  removeFromCart: (id: string) => void;
  cartQuantity: number;
  discount: number;
  coupon: string;
  totalPrice: number;
  setDiscount: (discount: number) => void;
  setCoupon: (coupon: string) => void;
  cartItems: CartItemType[];
};

const ShoppingCartContext = createContext<ShoppingCartContextType | undefined>(undefined);

export function useShoppingCart() {
  const context = useContext(ShoppingCartContext);
  if (context === undefined) {
    throw new Error("useShoppingCart must be used within a ShoppingCartProvider");
  }
  return context;
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [cartItems, setCartItems] = useLocalStorage<CartItemType[]>("shopping-cart", []);
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");

  const { products: storeItems } = useProducts();

  const openCart = () => setIsOpen(true);
  const closeCart = () => setIsOpen(false);
  const clearCart = () => {
    setCartItems([]);
    setDiscount(0);
    setCoupon("");
  };

  const getItemQuantity = (id: string) => {
    return cartItems.find(item => item.id === id)?.quantity || 0;
  };

  const increaseCartQuantity = (id: string, discountValue: number = 0) => {
    setCartItems(currItems => {
      const existingItem = currItems.find(item => item.id === id);

      let newCartItems;
      if (existingItem) {
        newCartItems = currItems.map(item =>
          item.id === id
            ? { ...item, quantity: item.quantity + 1, discount: discountValue || item.discount }
            : item
        );
      } else {
        newCartItems = [...currItems, { id, quantity: 1, discount: discountValue }];
      }

      return newCartItems;
    });
  };

  const decreaseCartQuantity = (id: string) => {
    setCartItems(currItems => {
      const existingItem = currItems.find(item => item.id === id);
      if (!existingItem) return currItems;

      if (existingItem.quantity === 1) {
        if (currItems.length === 1) closeCart();
        return currItems.filter(item => item.id !== id);
      } else {
        return currItems.map(item =>
          item.id === id ? { ...item, quantity: item.quantity - 1 } : item
        );
      }
    });
  };

  const removeFromCart = (id: string) => {
    setCartItems(currItems => currItems.filter(item => item.id !== id));
  };

  const cartQuantity = useMemo(
    () => cartItems.reduce((quantity, item) => quantity + item.quantity, 0),
    [cartItems]
  );

  const totalPrice = useMemo(() => {
    return cartItems.reduce((total, cartItem) => {
      const item = storeItems.find(i => i._id === cartItem.id);
      if (item) {
        const itemPrice = item.price.cost * (1 + item.price.margin / 100);
        const itemDiscount = itemPrice * ((cartItem.discount || discount) / 100);
        const finalItemPrice = itemPrice - itemDiscount;
        return total + finalItemPrice * cartItem.quantity;
      }
      return total;
    }, 0);
  }, [cartItems, storeItems, discount]);

  useEffect(() => {
    if (totalPrice === 0) {
      closeCart();
    }
  }, [totalPrice]);

  return (
    <ShoppingCartContext.Provider
      value={{
        openCart,
        closeCart,
        clearCart,
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        cartQuantity,
        discount,
        coupon,
        totalPrice,
        setDiscount,
        setCoupon,
        cartItems,
      }}
    >
      {children}
      <ShoppingCart isOpen={isOpen} closeCart={closeCart} />
    </ShoppingCartContext.Provider>
  );
}
