import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";

const Home = () => {
  return (
    <>
      <Navbar
        link="/contact"
        sell={false}
        title={process.env.REACT_APP_COMPANY_NAME + " - Contact"}
        name="Contact"
      />
      <Breadcrumb
        link="/contact"
        sell={false}
        title={process.env.REACT_APP_COMPANY_NAME + " - Contact"}
        name="Contact"
      />
      <br />
      <div className="container">
        <h1>Talk with us:</h1>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Your name:
          </label>
          <input
            type="name"
            className="form-control"
            id="name"
            placeholder="João Silva"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Your e-mail:
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="email@seu-email.com"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            How can we help you?
          </label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="4"
            placeholder="I would like to know more details about..."
          ></textarea>
        </div>
      </div>
      <Footer name="Home" />
    </>
  );
};

export default Home;
