import React from "react";

import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";

const Dental = () => {
  return (
    <>
      <Navbar
        link="/terms-and-conditions"
        title={process.env.REACT_APP_COMPANY_NAME + " - Terms and Conditions"}
        name="Terms and Conditions"
      />
      <Breadcrumb
        link="/terms-and-conditions"
        title={process.env.REACT_APP_COMPANY_NAME + " - Terms and Conditions"}
        name="Terms and Conditions"
      />
      <h1 className="container text-center">Terms and Conditions - {process.env.REACT_APP_COMPANY_NAME}</h1>
      <Footer name="Terms and Conditions" />
    </>
  );
};

export default Dental;
