import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../style/Ticket.css";

const Home = () => {
  function handleClick(event, content, content2) {
    if (document.getElementById(content))
      document.getElementById(content).classList.remove("invisible");
    if (document.getElementById(content2))
      document.getElementById(content2).classList.add("invisible");
    if (document.getElementById(content2))
      document.getElementById(content2).classList.remove("visible");
  }
  function Picker(content, content2) {
    const [value, onChange] = useState(new Date());
    if (document.getElementById(content))
      document.getElementById(content).classList.add("invisible");

    if (document.getElementById(content))
      document.getElementById(content).classList.remove("visible");

    if (document.getElementById(content2))
      document.getElementById(content2).classList.add("visible");

    if (document.getElementById(content2))
      document.getElementById(content2).classList.remove("invisible");

    if (document.getElementById(content2))
      document.getElementById(content2).innerHTML =
        value.toLocaleDateString() + " - Click to change";

    return (
      <div>
        <Calendar onChange={onChange} value={value} />
      </div>
    );
  }
  return (
    <>
      <Navbar
        link="/contact"
        sell={false}
        title={process.env.REACT_APP_COMPANY_NAME + " - Tickets"}
        name="Contact"
      />

      <br />
      <div className="container">
        <h1>Please fullfil your form:</h1>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Passenger's full name (as in passport):
          </label>
          <input
            type="name"
            className="form-control"
            id="name"
            placeholder="John Doe"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Birth date?
          </label>{" "}
          <button
            id="birth_date"
            className="form-label birth"
            onClick={(enablePicker) =>
              handleClick(enablePicker, "picker", "birth_date")
            }
          >
            Click to change
          </button>
          <div id="picker" className="container invisible">
            {Picker("picker", "birth_date")}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Place of birth:</label>
          <input
            type="name"
            className="form-control"
            id="place_of_birth"
            placeholder="San Francisco, CA"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Country of birth:</label>
          <input
            type="country"
            className="form-control"
            id="country_of_birth"
            placeholder="United States of America"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Your document?
          </label>
          <select id="document">
            <option value={0}>Ordinary passport</option>
            <option value={1}>Identity Card</option>
            <option value={2}>Diplomatic passport</option>
            <option value={3}>Service passport</option>
            <option value={4}>Special passport</option>
            <option value={5}>Alien's passport</option>
            <option value={6}>Travel document</option>
            <option value={7}>Nansen passport</option>
            <option value={8}>Other</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Document number:
          </label>
          <input
            type="name"
            className="form-control"
            id="passport_number"
            placeholder="JP39238XP"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Document Issue:
          </label>{" "}
          <button
            id="passport_issue"
            className="form-label birth"
            onClick={(pickerIssue) =>
              handleClick(pickerIssue, "pickerIssue", "passport_issue")
            }
          >
            Click to change
          </button>
          <div id="pickerIssue" className="container invisible">
            {Picker("pickerIssue", "passport_issue")}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Document Expire:
          </label>{" "}
          <button
            id="passport_expire"
            className="form-label birth"
            onClick={(pickerExpire) =>
              handleClick(pickerExpire, "pickerExpire", "passport_expire")
            }
          >
            Click to change
          </button>
          <div id="pickerExpire" className="container invisible">
            {Picker("pickerExpire", "passport_expire")}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Your supporting document (not required)?
          </label>
          <select id="supporting_doc">
            <option value={0}>Not present</option>
            <option value={1}>EU Visa</option>
            <option value={2}>Green Card</option>
            <option value={3}>Resident permit</option>
            <option value={4}>Other</option>
          </select>
          <input
            type="file"
            name="support_doc_file"
          // onChange={this.onChangeFile}
          />
          {/* <button onClick={uploadFile}>Upload</button> */}
        </div>
        <div className="mb-3">
          <label className="form-label">
            Mother's full name (as in passport):
          </label>
          <input
            type="name"
            className="form-control"
            id="mother"
            placeholder="John Mothers Doe"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">
            Father's full name (as in passport):
          </label>
          <input
            type="name"
            className="form-control"
            id="father"
            placeholder="John Fathers Doe"
          />
        </div>
      </div>
      <Footer name="Home" />
    </>
  );
};

export default Home;
