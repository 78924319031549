import { LanguageContext } from '../containers/Language';
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import useToken from '../components/useToken';

const Author = () => {
  const { dictionary } = useContext(LanguageContext);
  const { token } = useToken();
  const { id } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (id !== undefined && token !== null) {
      axios
        ({
          method: "GET",
          url: process.env.REACT_APP_BACKEND + "author/" + id,
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then((res) => {
          setData(res.data);
        })
        .catch(error => {
          // La respuesta fue hecha y el servidor respondió con un código de estado
          // que esta fuera del rango de 2xx
          if (error.response) {
            if (error.response.data.msg === "Token has expired") {
              localStorage.removeItem("token");
              window.location.reload(true);
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
            // http.ClientRequest en node.js
            console.log(error.request);
          } else {
            // Algo paso al preparar la petición que lanzo un Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    }
    else {
      if (token !== null)
        axios
          ({
            method: "GET",
            url: process.env.REACT_APP_BACKEND + "/author",
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
          .then((res) => {
            setData(res.data);
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.msg === "Token has expired") {
                localStorage.removeItem("token");
                window.location.reload(true);
              }
              // La respuesta fue hecha y el servidor respondió con un código de estado
              // que esta fuera del rango de 2xx
              console.log(error.response.data.msg);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // La petición fue hecha pero no se recibió respuesta
              // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
              // http.ClientRequest en node.js
              console.log(error.request);
            } else {
              // Algo paso al preparar la petición que lanzo un Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          })
    }
  }, [id, token])

  if (data.length > 0) return (
    <>
      <Navbar link="/author" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Author.Author} name={dictionary.Author.Author} />
      <Breadcrumb link="/author" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Author.Author} name={dictionary.Author.Author} />
      <div className="faq container"><h1>{dictionary.Author.Author}: {id}</h1></div>
      {data.map((author) => (
        // <>{JSON.stringify(data)}
        <div className="faq container" key={author._id.$oid}>
          <h1>{dictionary.Author.Name} : {author.name.first} {author.name.last}</h1>
          <h3>{dictionary.Author.Citizenship}: {author.country_id}</h3>
          <h3>{dictionary.Author.Email}: <a href={`mailto:${author.email}`}>{author.email}</a></h3>
          <a href={"mailto:" + process.env.REACT_APP_COMPANY_EMAIL}>{dictionary.Author.Moreinfo}</a>
        </div>
        // </>
      ))}
      <Footer name={dictionary.Author.author} />
    </>
  );
  else {
    if (token === null) {
      return (
        <>
          <Navbar link="/author" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Author.Author} name={dictionary.Author.Author} />
          <Breadcrumb link="/author" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Author.Author} name={dictionary.Author.Author} />
          <div className="faq container">
            <h1>{dictionary.Author.Token}</h1>
          </div>
          <Footer name={dictionary.Author.Author} />
        </>
      )
    }
    else {
      return (
        <>
          <Navbar link="/author" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Author.Author} name={dictionary.Author.Author} />
          <Breadcrumb link="/author" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Author.Author} name={dictionary.Author.Author} />
          <div className="faq container">
            <h1>{dictionary.Author.AuthorNotfound}</h1>
          </div>
          <Footer name={dictionary.Author.Author} />
        </>
      )

    }
  }
};
export default Author;

