import React, { useState, createContext } from 'react';
import { currencyOptions, currencyFactor } from '../currency';

export const CurrencyContext = createContext({
    currencySymbol: String(process.env.REACT_APP_CURRENCY_SYMBOL),
    currencyFactor: parseFloat(currencyFactor[process.env.REACT_APP_CURRENCY_SYMBOL])
});
// it provides the Currency context to app
export function CurrencyProvider({ children }) {
    const defaultCurrency = window.localStorage.getItem('netsystems-currency') || 'USD';
    const [userCurrency, setUserCurrency] = useState(defaultCurrency);
    const provider = {
        userCurrency,
        currencyFactor: parseFloat(currencyFactor[userCurrency]),
        currencySymbol: userCurrency,
        userCurrencyChange: selected => {
            const newCurrency = currencyOptions[selected] ? selected : process.env.REACT_APP_CURRENCY
            setUserCurrency(newCurrency)
            window.localStorage.setItem('netsystems-currency', newCurrency);
            window.localStorage.setItem('netsystems-currency-fx', currencyFactor[newCurrency]);
        }
    };
    return (
        <CurrencyContext.Provider value={provider}>
            {children}
        </CurrencyContext.Provider>
    );

};
