import React, { useState, useContext, useRef, useEffect } from "react";
import { useProducts } from "../Products";
import { CartItem } from "../components/CartItem";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { CurrencyContext } from "../containers/Currency";
import { LanguageContext } from '../containers/Language';
import { formatCurrency } from "../components/utils/formatCurrency";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import Paypal from "../components/Paypal";
import "../style/Checkout.css";
import FreteCalculator from '../components/FreteCalculator';

interface CouponState {
    message: string;
    error: number;
    applied: boolean;
}

const Checkout: React.FC = () => {
    const { products: storeItems, error } = useProducts();
    const { cartItems, setDiscount, setCoupon, discount } = useShoppingCart();
    const [couponState, setCouponState] = useState<CouponState>({ message: '', error: 0, applied: false });
    const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
    const { dictionary } = useContext(LanguageContext);
    const couponInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (cartItems.length === 0) {
            navigate('/');
        }
        couponInputRef.current?.focus();
    }, [cartItems, navigate]);

    // Calculate the total price of items in the cart
    const calculateTotal = () => {
        return cartItems.reduce((total, cartItem) => {
            const item = storeItems.find(i => i._id === cartItem.id);
            if (item) {
                const itemTotal = (item.price.cost * (1 + item.price.margin / 100)) * currencyFactor;
                return total + (itemTotal * cartItem.quantity);
            }
            return total;
        }, 0);
    };

    // Use effect to recalculate total when storeItems are loaded, or cartItems/currencyFactor change
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        if (storeItems.length > 0) {
            const newTotal = calculateTotal();
            setTotal(newTotal);
        }
    }, [storeItems, cartItems, currencyFactor]);

    // Calculate the final total after applying the discount
    const finalTotal = discount > 0
        ? total - (total * (discount / 100))
        : total;

    const handleCouponApply = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/coupon/${couponState.message}`);
            const coupons = await response.json();

            if (!coupons || coupons.used === 1) {
                setCouponState({
                    ...couponState,
                    message: "Coupon already used!",
                    error: coupons ? 2 : 1,
                    applied: false
                });
                return;
            }

            setCoupon(coupons._id.$oid);
            setDiscount(coupons.discount);

            setCouponState({
                ...couponState,
                error: 0,
                applied: true
            });

        } catch (error) {
            console.error('API request failed:', error);
            setCouponState({
                ...couponState,
                error: 3,
                applied: false
            });
        }
    };
    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCouponState(prevState => ({ ...prevState, message: event.target.value }));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleCouponApply();
        }
    };

    if (error) {
        return <div className="text-danger">Error fetching products: {error}</div>;
    }

    return (
        <>
            <Navbar
                link="/checkout"
                title={`${process.env.REACT_APP_COMPANY_NAME} - ${dictionary.Checkout.Payment}`}
                name={dictionary.Checkout.Payment}
            />
            <Breadcrumb
                link="/checkout"
                title={`${process.env.REACT_APP_COMPANY_NAME} - ${dictionary.Checkout.Payment}`}
                name={dictionary.Checkout.Payment}
            />

            <Container className="checkout-container">
                <Row>
                    <Col md={7} className="order-summary">
                        <h2>{dictionary.Checkout.OrderSummary}</h2>
                        {cartItems.length > 0 ? (
                            cartItems.map(item => (
                                <CartItem key={item.id} id={item.id} quantity={item.quantity} />
                            ))
                        ) : null}
                        <hr />
                        <div className="total-price ms-auto fw-bold fs-5">
                            {dictionary.Cart.Total} {formatCurrency(total, currencySymbol)}
                        </div>
                    </Col>
                    <Col md={4} className="btn space coupon-section">
                        <div className="coupon-box">
                            <h4>{dictionary.Checkout.ApplyCoupon}</h4>
                            {couponState.error === 1 && (
                                <p className="text-danger">{dictionary.Checkout.NotFound} {couponState.message}</p>
                            )}
                            {couponState.error === 2 && (
                                <p className="text-danger">{dictionary.Checkout.AlreadyUsed} {couponState.message}</p>
                            )}
                            {couponState.error === 3 && (
                                <p className="text-danger">{dictionary.Checkout.ErrorApplying} {couponState.message}</p>
                            )}
                            {couponState.applied ? (
                                <>
                                    <p className="original-price text-danger">
                                        {dictionary.Checkout.BeforeDiscount}: {formatCurrency(total, currencySymbol)}
                                    </p>
                                    <p className="discounted-price text-success">
                                        {dictionary.Checkout.AppliedDiscount}: {discount}%
                                    </p>
                                    <p className="final-price text-primary">
                                        {dictionary.Checkout.TotalAfterDiscount}: {formatCurrency(finalTotal, currencySymbol)}
                                    </p>
                                    <Form.Group>
                                        <Form.Label>{dictionary.Checkout.Coupon}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            ref={couponInputRef}
                                            value={couponState.message}
                                            onChange={handleMessageChange}
                                            onKeyDown={handleKeyDown}
                                            placeholder={dictionary.Checkout.Code}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Button
                                        onClick={handleCouponApply}
                                        variant="success"
                                        className="mt-2"
                                        disabled
                                    >
                                        {dictionary.Checkout.ApplyCoupon}
                                    </Button>
                                </>
                            ) : (
                                <Form onSubmit={(event) => {
                                    event.preventDefault();
                                    handleCouponApply();
                                }}>
                                    <Form.Group>
                                        <Form.Label>{dictionary.Checkout.Coupon}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            ref={couponInputRef}
                                            value={couponState.message}
                                            onChange={handleMessageChange}
                                            onKeyDown={handleKeyDown}
                                            placeholder={dictionary.Checkout.Code}
                                        />
                                    </Form.Group>
                                    <Button
                                        onClick={handleCouponApply}
                                        variant="success"
                                        className="mt-2"
                                    >
                                        {dictionary.Checkout.ApplyCoupon}
                                    </Button>
                                </Form>
                            )}
                        </div>
                        <div className="btn">
                            <Paypal total={finalTotal} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div>

                <h1>Calculadora de Frete com API dos Correios</h1>
                <FreteCalculator
                    servico="04014" // Example service code for SEDEX
                    cepOrigem="01001-000"
                    // cepDestino="20040-000"
                    produtos={[{ qtd: 1, peso: 1, altura: 10, largura: 16, comprimento: 10 }]}
                    maoPropria={false}
                    valorDeclarado={100.0}
                    avisoRecebimento={true}
                />

            </div>
            <Footer name={dictionary.Checkout.Checkout} />
        </>
    );
};

export default Checkout;
