import React, { useContext } from 'react';
import Carousel from "../Carousel";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import { LanguageContext } from '../containers/Language';


const About = () => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <>
      <Navbar
        link="/about"
        title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.About.AboutUs}
        name="Sobre"
      />
      <Breadcrumb link="/about" name={dictionary.About.AboutUs} title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.About.AboutUs} />
      <Carousel />

      <div className="container">
        <h1 className="text-center">{process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.About.AboutUs}</h1>
        <div>
          <ul>
            <li>{dictionary.About.AboutFull}</li>
          </ul>
        </div>
      </div>

      <Footer name={dictionary.About.AboutUs} />
    </>
  );
};

export default About;
