import React, { useContext } from 'react';
import { languageOptions } from '../data/languages';
import { LanguageContext } from '../containers/Language';
import "../style/Navbar.css";

export default function LanguageSelector() {
    const { userLanguage, userLanguageChange } = useContext(LanguageContext);
    // set selected language by calling context method
    const handleLanguageChange = e => {
        userLanguageChange(e.target.value)
    };
    return (
        <div className='language'>
            <select
                onChange={handleLanguageChange}
                value={userLanguage}
            >
                {Object.entries(languageOptions).map(([id, name]) => (
                    <option key={id} value={id}>{name}</option>
                ))}
            </select>
        </div>
    )
}

