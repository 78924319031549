import React, { useState, useEffect } from "react";
import Carouselist from "./components/Carouselist";

function Carousel() {
  const [carousels, setCarousel] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND + "/carousel", {
      methods: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => setCarousel(response))
      .catch((error) => console.log(error));
  }, []);

  return <Carouselist carousels={carousels} />;
}
export default Carousel;
