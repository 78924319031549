import React, { useState, useContext, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { CurrencyContext } from "../containers/Currency";
import "../style/Paypal.css";
import { useProducts } from "../Products";
import { ProductType } from "../models/ProductType";

interface PayPalItem {
    sku: string;
    name: string;
    quantity: number;
    unit_amount: {
        currency_code: string;
        value: string;
    };
    description: string;
}

interface CartItemType {
    id: string;
    quantity: number;
}

interface PayPalProps {
    total: number;
}

const roundToTwoDecimals = (num: number): number => Math.round(num * 100) / 100;

const PayPal: React.FC<PayPalProps> = ({ total }) => {
    const [success, setSuccess] = useState(false);
    const [paymentProblems, setPaymentProblems] = useState(false);
    const [orderID, setOrderID] = useState<any>(null);
    const [dataID, setDataID] = useState<any>(null);
    const [items, setItems] = useState<PayPalItem[]>([]);
    const [itemTotalBeforeDiscount, setItemTotalBeforeDiscount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const { cartItems, discount, coupon } = useShoppingCart();
    const { products: storeItems } = useProducts();
    const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
    const date = new Date().toJSON();
    const defaultLanguage = window.localStorage.getItem('netsystems-languages') || process.env.REACT_APP_LANGUAGE || "en";

    useEffect(() => {
        const calculateTotals = () => {
            let totalBeforeDiscount = 0;
            let itemsList: PayPalItem[] = [];

            cartItems.forEach((cartItem: CartItemType) => {
                const item = storeItems.find((i: ProductType) => i._id === cartItem.id);
                if (item) {
                    const basePrice = item.price.cost;
                    const marginPrice = basePrice * (item.price.margin / 100);
                    const unitPrice = basePrice + marginPrice;
                    const itemTotal = unitPrice * cartItem.quantity;
                    totalBeforeDiscount += itemTotal;

                    const itemName = item.name?.[defaultLanguage] || item.name?.[process.env.REACT_APP_LANGUAGE || "en"] || "Unknown Product";

                    itemsList.push({
                        sku: item._id,
                        name: itemName,
                        quantity: cartItem.quantity,
                        unit_amount: {
                            currency_code: currencySymbol,
                            value: roundToTwoDecimals(unitPrice * currencyFactor).toFixed(2),
                        },
                        description: itemName,
                    });
                }
            });

            setItems(itemsList);
            setItemTotalBeforeDiscount(roundToTwoDecimals(totalBeforeDiscount * currencyFactor));
            setIsLoading(false); // Set loading to false after totals are calculated
        };

        calculateTotals();
    }, [cartItems, storeItems, currencyFactor, defaultLanguage, currencySymbol, discount, total]);

    const createOrder = (data: any, actions: any) => {
        const finalTotal = total;
        const discountAmount = itemTotalBeforeDiscount - finalTotal;

        if (finalTotal > 0) {
            return actions.order.create({
                purchase_units: [{
                    amount: {
                        currency_code: currencySymbol,
                        value: roundToTwoDecimals(finalTotal).toFixed(2),
                        breakdown: {
                            item_total: {
                                currency_code: currencySymbol,
                                value: roundToTwoDecimals(itemTotalBeforeDiscount).toFixed(2),
                            },
                            discount: {
                                currency_code: currencySymbol,
                                value: roundToTwoDecimals(discountAmount).toFixed(2),
                            },
                        },
                    },
                    items,
                }],
            });
        }
    };

    const writeOrder = async (order: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_PAYMENT_ADDRESS as string, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(order),
            });
            const data = await response.json();
            setOrderID(data);
        } catch (error) {
            console.error("Error writing order:", error);
            setPaymentProblems(true);
        }
    };

    const onApprove = (data: any, actions: any) => {
        return actions.order.capture().then((details: any) => {
            setOrderID(details.payer);
            setDataID(data);
            setSuccess(true);
            setPaymentProblems(false);
            writeOrder({ data, payer: details.payer, product: items, discount, coupon, date });
            // clearCart();
        });
    };

    const onError = (data: any) => {
        setPaymentProblems(true);
    };

    const handleGoHome = () => {
        window.location.href = '/';
    };

    return (
        <div className={`paypal-container ${success ? 'success' : ''}`}>
            {paymentProblems && (
                <div className="payment-error">
                    <h1>Payment problems, please retry.</h1>
                </div>
            )}
            {success ? (
                <div className="success-message">
                    <h1>Thank You {orderID?.payer?.name?.given_name} for Your Order!</h1>
                    <p>Thanks for your order, {orderID?.payer?.name?.given_name} {orderID?.payer?.name?.surname}.</p>
                    <p>Country to deliver to: {orderID?.payer?.address?.country_code}</p>
                    <p>Your PayPal ID is {orderID?.payer?.payer_id}</p>
                    <p>Your Data ID is {JSON.stringify(dataID)}</p>
                    <p>More information will be sent to your email: {orderID?.payer?.email_address}.</p>
                    <button onClick={handleGoHome}>Go to Home</button>
                </div>
            ) : (
                !isLoading && (
                    <PayPalScriptProvider
                        options={{
                            "client-id": process.env.REACT_APP_PAYPAL_ID as string,
                            "currency": currencySymbol,
                        }}
                    >
                        <div className="paypal-buttons">
                            <PayPalButtons
                                style={{ layout: "vertical" }}
                                createOrder={createOrder}
                                onApprove={onApprove}
                                onError={onError}
                                forceReRender={[cartItems, currencySymbol, discount, total]} // Ensure this array includes all relevant variables
                            />
                        </div>
                    </PayPalScriptProvider>
                )
            )}</div>);
};
export default PayPal;