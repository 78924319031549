import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Faqs = () => {
  const { id } = useParams()
  const [data, setData] = useState([]);
  const fetchFaq = (id) => {
    id = id.replaceAll(' ', '%20')
    console.log(id)
    if (id !== undefined)
      axios
        .get(`${process.env.REACT_APP_BACKEND}faq/${id}`)
        .then((res) => { setData(res.data); })
        .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchFaq(id);

  }, [id]);
  if (data.country !== undefined) {
    return (

      <>
        <Navbar link="/faq" title={process.env.REACT_APP_COMPANY_NAME + " - FAQs"} name="Faqs" />
        <Breadcrumb link="/faq" title={process.env.REACT_APP_COMPANY_NAME + " - FAQs"} name="Faqs" />
        <div className="faq container">
          <h1>Faq: {data.question}</h1>
        </div>
        <div className="faq container" key={data._id}>
          <h1>Question: {data.question}</h1>
          <h3>Answer: {data.answer}</h3>
          <h3>Country: {data.country.name}</h3>
          <h3>Upvote Count: {data.upvote_count}</h3>
          <h3>Author: {data.author.name} {data.author.surname}</h3>

          <small> in {new Date(data.date_created).toDateString()}</small>
          <a href={"mailto:" + process.env.REACT_APP_COMPANY_EMAIL}>More info</a>
        </div>
        <Footer name="Faq" />
      </>
    )

  }
  else {
    return (
      <h1>Error loading FAQ</h1>
    )
  }
}
export default Faqs;

