import React from "react";
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <>
      <Navbar
        link="/privacy"
        title={process.env.REACT_APP_COMPANY_NAME + " - Privacy and usage conditions"}
        name="Privacy"
      />
      <Breadcrumb
        link="/privacy"
        title={process.env.REACT_APP_COMPANY_NAME + " - Privacy and usage conditions"}
        name="Privacy"
      />
      <h1 className="container text-center">Privacy</h1>
      <Footer name="Terms-and-conditions" />
    </>
  );
};

export default Privacy;
