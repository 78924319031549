import React, { useContext } from 'react';
import { useProducts } from "../Products"; // Ensure this hook is defined correctly
import { Button, Stack, Row } from "react-bootstrap";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { formatCurrency } from "./utils/formatCurrency";
import { CurrencyContext } from "../containers/Currency";
import "../style/CartItem.css";
import { ProductType } from "../models/ProductType";

type CartItemProps = {
  id: string;
  quantity: number;
};

export function CartItem({ id, quantity }: CartItemProps) {
  const defaultLanguage = window.localStorage.getItem('netsystems-languages') || process.env.REACT_APP_LANGUAGE || "en";
  const { products } = useProducts(); // Use the hook to get products
  const { decreaseCartQuantity, increaseCartQuantity, closeCart } = useShoppingCart();
  const { currencyFactor, currencySymbol } = useContext(CurrencyContext);

  const item = products.find((product: ProductType) => product._id === id);

  if (!item) return <div>Item not found</div>; // Handle missing items gracefully

  // Type assertion to ensure key exists
  const name = item.name[defaultLanguage as keyof typeof item.name] || item.name[process.env.REACT_APP_LANGUAGE as keyof typeof item.name] || "Unnamed Product";

  const unitPrice = (item.price.cost * (1 + item.price.margin / 100)) * currencyFactor;
  const totalPrice = unitPrice * quantity;
  if (totalPrice === 0) {
    closeCart();
  }

  return (
    <Stack direction="horizontal" gap={3} className="d-flex align-items-center cart-item">
      <img
        src={item.image.main}
        alt={item.image.alt_main}
        className="checkout-image"
      />
      <Row className="me-auto item-details">
        <div className="item-name">
          {name}
          {quantity > 1 && (
            <span className="item-quantity text-muted">
              x{quantity}
            </span>
          )}
        </div>
        <div className="item-price text-muted">
          {formatCurrency(unitPrice, currencySymbol)}
        </div>
      </Row>
      <Button
        className="quantity-btn increase-quantity-btn"
        size="sm"
        onClick={() => increaseCartQuantity(id)}
      >
        +
      </Button>
      <div className="total-price">
        {formatCurrency(totalPrice, currencySymbol)}
      </div>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => decreaseCartQuantity(id)}
        className="quantity-btn"
      >
        -
      </Button>
    </Stack>
  );
}
