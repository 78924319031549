import React from "react"; import axios from "axios";

function Logout(props) {

    function logMeOut() {
        axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND + "/logout",
        })
            .then((response) => {
                // console.log(response)
                localStorage.setItem('response', response)
                props.token()
                // window.location.reload(true);
            }).catch((error) => {
                if (error.response) {
                    localStorage.setItem('response', (error.response))
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            })
    }

    return (
        <header className="App-header">
            <button className="btn-outline-danger btn-sm" onClick={logMeOut}>
                Logout
            </button>
        </header>
    )
}

export default Logout;
