import { useState, useEffect } from "react";
import { ProductType } from "./models/ProductType";

// Define the type for the hook's return value
interface UseProductsResult {
  products: ProductType[];
  error: string | null;
}

export function useProducts(): UseProductsResult {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/products`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Ensure data is of type ProductType[]
        if (Array.isArray(data)) {
          setProducts(data as ProductType[]);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        // Use `Error` type assertion to ensure proper typing
        setError((error as Error).message);
        console.error("Fetching products failed:", error);
      }
    };

    fetchProducts();
  }, []);

  return { products, error };
}
