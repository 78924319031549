import React, { useContext } from "react";
import { LanguageContext } from '../containers/Language';
import Link from "./Link";
import "../style/Faq.css";

const CountryNavList = (props) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <>
      <ul className="navbar-nav">
        <li className="nav-item navbar-light dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="/"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {dictionary.Menu.Products}
          </a>
          {JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("black") ||
            JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("#000") ||
            JSON.stringify(process.env.REACT_APP_BACKGROUND_COLOR) === JSON.stringify("#000000") ?
            <ul
              className="dropdown-menu"
              style={{ "backgroundColor": "black" }}
              aria-labelledby="navbarDropdownMenuLink"
            >
              <ul className="Faq">
                {
                  props.categories &&
                  props.categories.map((category) => {
                    return (
                      <li className="nav-link" key={category._id}>
                        <Link
                          page={category.name}
                          to={'/category/' + category.name}
                          pass="0"
                        >
                          {category.name}
                        </Link>
                      </li>
                    );
                  })}
                <li className="nav-link">
                  <Link pass="0" page="Category" nome="Category" to="/product">
                    {dictionary.Menu.AllProducts}
                  </Link>
                </li>
              </ul>
            </ul>
            :
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <ul className="Faq">
                {props.categories &&
                  props.categories.map((category) => {
                    return (
                      <li className="nav-link" key={category._id}>
                        <Link
                          page={category.name}
                          to={'/category/' + category.name}
                          pass="0"
                        >
                          {category.name}
                        </Link>
                      </li>
                    );
                  })}
                <li className="nav-link">
                  <Link pass="0" page="Category" nome="Category" to="/product">
                    {dictionary.Menu.AllProducts}
                  </Link>
                </li>
              </ul>
            </ul>}
        </li>
      </ul >
    </>
  );
};

export default CountryNavList;
