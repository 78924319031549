import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { StoreItem } from './StoreItem';
import { useProducts } from '../Products';
import { ProductType } from '../models/ProductType';  // Ensure consistent import
import '../style/Store.css';  // Import the new CSS

export function Store() {
    const { products: storeItems } = useProducts();

    return (
        <Container className="store-container">
            <Row md={2} xs={1} lg={3} className="g-4">
                {storeItems.map((item: ProductType) => (
                    <Col key={item._id} className="store-item-col">
                        <StoreItem {...item} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
