import fr from './fr.json';
import en from './en.json';
import pt from './pt-br.json';
import es from './es.json';
import it from './it.json';
import ru from './ru.json';
import de from './de.json';
import hi from './hi.json';
export const dictionaryList = { pt, en, fr, it, es, ru, de, hi };
export const languageOptions = {
    pt: 'Português',
    fr: 'Français',
    en: 'English',
    it: 'Italiano',
    ru: 'Русский',
    de: 'Deutsch',
    es: 'Español',
    hi: 'हिंदी',
};