import { useNavigate } from "react-router-dom";

type Linkz = {
  children?: React.ReactNode
  page?: string
  nome?: string
  to?: string
}

type Events = {
  children?: React.ReactNode
  preventDefault?: any
  value?: string
  event?: React.MouseEvent
}
const Links = ({ to, children, nome, page }: Linkz) => {
  let navigate = useNavigate();
  const preventReload = (event: Events) => {
    event.preventDefault();
    window.history.pushState({}, "", to);
    navigate(to!, { replace: false });
  };
  if (nome !== undefined) {
    if (nome === page) {
      return (<a href={to} className={"nav-link active"} onClick={preventReload}>{children}</a>);
    }
    else {
      return (<a href={to} className={"nav-link"} onClick={preventReload}>{children}</a>);
    }
  }
  else {
    return (<a href={to} className={nome} onClick={preventReload}>{children}</a>);
  }
}

export default Links;
