// Footer.tsx
import React from "react";
import Link from "./Link";
import "../style/Footer.css";

// Define the interface for the props
interface FooterProps {
  name?: string; // The name prop is optional
}

const Footer: React.FC<FooterProps> = ({ name }) => {
  return (
    <>
      <footer>
        <div className="footer footer__wrapper">
          <div className="footer__left">
            <ul className="footer__social-media">
              <li>
                <a href={process.env.REACT_APP_FACEBOOK_LINK} target="_blank" rel="noreferrer">
                  <i className="fa fa-facebook fa-lg sm-item"></i>
                </a>
                <a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank" rel="noreferrer">
                  <i className="fa fa-instagram fa-lg sm-item"></i>
                </a>
                <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank" rel="noreferrer">
                  <i className="fa fa-youtube fa-lg sm-item"></i>
                </a>
                <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}?subject=Website contact`} target="_blank" rel="noreferrer">
                  <i className="fa fa-envelope fa-lg sm-item"></i>
                </a>
                <a href="/static/files/cv_rodrigo.pdf" target="_blank" rel="site">
                  <i className="fa fa-download">CV - Rodrigo</i>
                </a>
              </li>
            </ul>
            <p className="footer__text">
              <a href="/static/files/cv_rodrigo.pdf" target="_blank" rel="noreferrer">
                &#169;Rodrigo Conde Attanasio - JavaScript + React + Backend Python/Flask - {process.env.REACT_APP_YEAR}
              </a> |
              <Link to="/terms-and-conditions">Terms &amp; Conditions</Link> |
              <Link to="/privacy">Privacy</Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
