import React, { useContext } from 'react';
import { Button, Offcanvas, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { CartItem } from "./CartItem";
import { formatCurrency } from "./utils/formatCurrency";
import { useProducts } from "../Products"; // Adjusted import
import { LanguageContext } from '../containers/Language';
import { CurrencyContext } from "../containers/Currency";
import "../style/ShoppingCart.css";
import { ProductType } from "../models/ProductType";

// Define the type for the props
interface ShoppingCartProps {
  isOpen: boolean;
  closeCart: () => void;
}

export function ShoppingCart({ isOpen, closeCart }: ShoppingCartProps) {
  const { cartItems } = useShoppingCart();
  const { products: storeItems } = useProducts(); // Fetch products as an array of ProductType
  const { dictionary } = useContext(LanguageContext);
  const { currencyFactor, currencySymbol } = useContext(CurrencyContext);
  const navigate = useNavigate();

  const total = cartItems.reduce((sum, cartItem) => {
    const item = storeItems.find((i: ProductType) => i._id === cartItem.id);
    const cost = item?.price.cost ?? 0;
    const margin = item?.price.margin ?? 0;
    return sum + ((cost * (1 + margin / 100)) * currencyFactor) * cartItem.quantity;
  }, 0);

  const handleCheckout = () => {
    navigate("/checkout", { replace: true });
    closeCart();
  };

  return (
    <Offcanvas show={isOpen} onHide={closeCart} placement="end" className="shopping-cart">
      <Offcanvas.Header closeButton className="shopping-cart-header">
        <Offcanvas.Title>{dictionary.Cart.Cart}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="shopping-cart-body">
        <Stack gap={3}>
          <div className="cart-items">
            {cartItems.length > 0 ? (
              cartItems.map(item => (
                <CartItem key={item.id} id={item.id} quantity={item.quantity} />
              ))
            ) : (
              <p className="empty-cart">{dictionary.Cart.EmptyCart}</p>
            )}
          </div>
          <div className="total-price ms-auto fw-bold fs-5">
            {dictionary.Cart.Total} {formatCurrency(total, currencySymbol)}
          </div>
          <Button
            className="checkout-btn mt-3"
            onClick={handleCheckout}
            disabled={cartItems.length === 0}
          >
            {dictionary.Cart.Checkout}
          </Button>
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
