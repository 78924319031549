import React, { useContext } from 'react';
import Link from "./Link";
import { LanguageContext } from '../containers/Language';
import "../style/Breadcrumb.css";
const Breadcrumb = (props) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <>
      <nav aria-label="breadcrumb" className="Breadcrumb container">
        <ol className="breadcrumbs top">
          <li className="breadcrumb-item">
            <Link to="/">{dictionary.Menu.Home}</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={props.link} title={props.title}>
              {props.name}
            </Link>
          </li>
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;
