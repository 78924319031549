import React, { useState } from 'react';

interface Produto {
    qtd: number;
    peso: number;
    altura: number;
    largura: number;
    comprimento: number;
}

interface FreteProps {
    servico: string;
    cepOrigem: string;
    produtos: Produto[];
    maoPropria?: boolean;
    valorDeclarado?: number;
    avisoRecebimento?: boolean;
}

const FreteCorreios: React.FC<FreteProps> = ({
    servico,
    cepOrigem,
    produtos,
    maoPropria = false,
    valorDeclarado = 0,
    avisoRecebimento = false,
}) => {
    const [cepDestino, setCepDestino] = useState<string>('');
    const [freteTotal, setFreteTotal] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);

    const BEARER_TOKEN = 'eyJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MjUzOTE5ODcsImlzcyI6InRva2VuLXNlcnZpY2UiLCJleHAiOjE3MjU0NzgzODcsImp0aSI6IjNlYzQ1YzNlLWNhMjEtNDVkZi1hOTZmLTk2NDg4YTYwYjRlMiIsImFtYmllbnRlIjoiUFJPRFVDQU8iLCJwZmwiOiJQSiIsImlwIjoiNDYuNi40NS4yMTksIDE5Mi4xNjguMS4xMzEiLCJpZCI6IjExNzY2MzA3MDAwMTE0IiwiY25waiI6IjExNzY2MzA3MDAwMTE0In0.DNMAEalGS6O8AEdhaX3Rrgo4ELSNL9IYBhugxM1CKJCbLICYFLccPoO-xwGm6ABtIQpX3ZjwkfQ3QKdMBVsmWWe6uWLqzGH9envyIw1RanL4XSVgMRiU0r_7lD9yStiQ0Vko1SjCPtyFj6nXhCH1xsIO5nMRRDILOggMUvOiY0CAYuLPcrehFVqI-ZfacowMP1GJvBiEnnRrpgnaT1GbXkVAeo6Ln6G9zixts4yu12x-gMg7CJHDaEu9fvFnOqonbwu6pdC4qSScO9X2Ogxa1Je_Zx8AsxV8AsJsJICTCc0dKEHvAwB9sHCBWXLfmizzVQVxvdNL0YW8-6mEjeFsdw';
    const validaCEP = (valor: string): boolean => {
        return /^[0-9]{5}-?[0-9]{3}$/.test(valor);
    };

    const validaProdutos = (produtos: Produto[]): boolean => {
        const requiredFields = ['qtd', 'peso', 'altura', 'largura', 'comprimento'];
        return produtos.every((produto) =>
            requiredFields.every((field) => produto[field as keyof Produto] !== undefined)
        );
    };

    const formataCEP = (cep: string): string => {
        return cep.replace(/\D/g, '');
    };

    const formataValorBooleano = (valor: boolean): string => {
        return valor ? 's' : 'n';
    };

    const formataValorEmReais = (valor: string): number => {
        const formattedValue = valor.replace('.', '').replace(',', '.');
        return parseFloat(formattedValue);
    };
    const calcular = async (): Promise<void> => {
        setError(null);

        if (!validaCEP(cepOrigem) || !validaCEP(cepDestino) || !validaProdutos(produtos)) {
            setError('Dados inválidos. Verifique os campos e tente novamente.');
            return;
        }

        const pesoTotal = produtos.reduce((total, { peso, qtd }) => total + (peso * qtd), 0);
        const volumeTotal = produtos.reduce((total, { altura, largura, comprimento, qtd }) => total + (altura * largura * comprimento * qtd), 0);

        const medida = Math.ceil(Math.cbrt(volumeTotal));
        const peso = pesoTotal < 0.3 ? 0.3 : pesoTotal;

        const formData: Record<string, string> = {
            nCdEmpresa: '',
            sDsSenha: '',
            sCepOrigem: formataCEP(cepOrigem),
            sCepDestino: formataCEP(cepDestino),
            nVlPeso: peso.toFixed(2),
            nCdFormato: '1',
            nVlComprimento: (medida < 16 ? 16 : medida).toString(),
            nVlAltura: (medida < 16 ? 16 : medida).toString(),
            nVlLargura: (medida < 16 ? 16 : medida).toString(),
            sCdMaoPropria: formataValorBooleano(maoPropria),
            nVlValorDeclarado: valorDeclarado.toFixed(2),
            sCdAvisoRecebimento: formataValorBooleano(avisoRecebimento),
            nCdServico: servico,
            nVlDiametro: '0',
            StrRetorno: 'xml',
            nIndicaCalculo: '3',
        };

        const queryString = new URLSearchParams(formData).toString();
        const url = `http://ws.correios.com.br/calculador/CalcPrecoPrazo.aspx?${queryString}`;

        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${BEARER_TOKEN}`, // Adding the Bearer token to the request
                },
            });

            const data = await response.text();
            const parser = new DOMParser();
            const xml = parser.parseFromString(data, 'application/xml');
            const valor = xml.getElementsByTagName('Valor')[0].textContent;

            if (valor) {
                setFreteTotal(formataValorEmReais(valor));
            } else {
                setError('Erro ao calcular o frete. Tente novamente mais tarde.');
            }
        } catch (error) {
            setError('Erro ao consultar o webservice dos Correios. Verifique sua conexão e tente novamente.');
            console.error('Error fetching freight data:', error);
        }
    };

    return (
        <div>
            <h1>Cálculo de Frete</h1>
            <div>
                <label>
                    CEP de Destino:
                    <input
                        type="text"
                        value={cepDestino}
                        onChange={(e) => setCepDestino(e.target.value)}
                        placeholder="Digite o CEP de destino"
                    />
                </label>
                <button onClick={calcular}>Calcular Frete</button>
            </div>
            {freteTotal !== null && <p>Valor do Frete: R$ {freteTotal.toFixed(2)}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default FreteCorreios;
