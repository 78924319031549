import Navbar from "../components/Navbar";
import Carousel from "../Carousel";
import Footer from "../components/Footer";
import { Store } from "../components/Store";
import React, { useContext } from 'react';
import { LanguageContext } from '../containers/Language';
import "../style/Home.css";

const Home = () => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <>
      <Navbar link="/" title={process.env.REACT_APP_COMPANY_NAME + " - " + dictionary.Home.Welcome} name={dictionary.Home.Welcome} />
      <Carousel />
      <br />
      <Store />
      <Footer name={dictionary.Home.Welcome} />
    </>
  );
};

export default Home;
